
import { InHistorySchema, InState, readInHistory } from '@/modules/in/in.api'
import { readOutHistory } from '@/modules/out/out.api'
import { defineComponent, ref } from 'vue'
import * as R from 'ramda'
import TheInHistoryItem from '@/pages/transaction/components/TheInHistoryItem.vue'
import TheOutHistoryItem from '@/pages/transaction/components/TheOutHistoryItem.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import { defaultToArray } from '@/common/common'

type TList = Array<InHistorySchema>
const request = [readInHistory, readOutHistory]

export default defineComponent(
  {
    name: 'History',
    components: { Tab, PageWithHeader, TheInHistoryItem, TheOutHistoryItem },
    setup () {
      const type = ref(0)
      const list = ref<TList | null>(null)

      const getComponent = (type: number) => {
        return [TheInHistoryItem, TheOutHistoryItem][type]
      }

      const setList = (res: TList) => {
        list.value = res
      }

      const getList = (type: number) => {
        request[type](
          {
            page: 1,
            pageCount: 100,
            status: InState.SUCCESS,
          },
        )
          .then((resp: InHistorySchema[]) => R.o(setList, defaultToArray)(resp))
          .catch(R.o(setList, R.always([])))
      }

      const getType = (index: number) => {
        type.value = index
        list.value = null
        getList(index)
      }

      getList(type.value)

      return {
        getType,
        type,
        getComponent,
        list,
      }
    },
  },
)
