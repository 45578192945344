
import { OutState } from '@/modules/out/out.api'
import { defineComponent } from 'vue'
import { OutStateDescriber } from '@/pages/transaction/history'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import Money from '@/components.global/Money'
import Line from '@/components/Line.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { translate } from '@/i18n'

export default defineComponent(
  {
    name: 'TheOutHistoryItem',
    components: { Holder, List, Line, Money, Time, Icon },
    props: {
      history: {
        type: Array,
        require: true,
      },
    },
    setup () {
      const status = (s: OutState) => {
        return OutStateDescriber[s]
      }

      const showReason = (s: OutState) => {
        return s === OutState.FAIL || s === OutState.REJECT
      }

      return { status, showReason, translate }
    },
  },
)
