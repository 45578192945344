import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "df-middle",
  style: {"height":"48px"}
}
const _hoisted_2 = { class: "flex-1 t-center f-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Money = _resolveComponent("Money")
  const _component_Time = _resolveComponent("Time")
  const _component_Line = _resolveComponent("Line")
  const _component_List = _resolveComponent("List")
  const _component_Holder = _resolveComponent("Holder")

  return (_openBlock(), _createBlock(_component_Holder, { content: _ctx.history }, {
    default: _withCtx(() => [
      _createVNode(_component_List, { "list-data": _ctx.history }, {
        default: _withCtx(({ item }) => [
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_Money, {
              v: item.amount,
              class: "flex-1 f-bold"
            }, null, 8, ["v"]),
            _createVNode("div", _hoisted_2, _toDisplayString(item.rechargeChannelName), 1),
            _createVNode(_component_Time, {
              class: "flex-2 t-right f-sm",
              format: "dd-MM-yy HH:mm:ss",
              time: item.createTime
            }, null, 8, ["time"])
          ]),
          _createVNode(_component_Line)
        ]),
        _: 1
      }, 8, ["list-data"])
    ]),
    _: 1
  }, 8, ["content"]))
}