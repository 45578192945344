
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'

export default defineComponent(
  {
    name: 'TheInHistoryItem',
    components: { Holder, List, Line, Time, Money },
    props: {
      history: {
        type: Array,
        require: true,
      },
    },
  },
)
